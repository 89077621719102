@import "/src/styles/mixin";

.shops {
  margin-bottom: 50px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 0;
  }
}

.title {
  @include font30;
  margin-bottom: 8px;
  &Wrap {
    margin-bottom: 50px;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      margin-bottom: 24px;
    }
  }
}
.tel {
  @include font14Bold;
  display: inline-block;
  padding: 2px 12px;
  background-color: var(--primary-color);
  border-radius: var(--border-radius-small);
}
