@import "../../../../../styles/mixin";

.shops {
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    flex-direction: column;
  }
}

.map_wrapper {
  width: 100%;
  //min-width: 995px;
  height: 400px;
  min-height: 500px;
  z-index: 0;
}

.marker_wrap {
  position: relative;

  .custom_marker {
    position: absolute;
    z-index: 1;
    left: -10px;
    top: -24px;

    .search_tag {
      path {
        fill: var(--red-danger-color);
      }
    }
  }
}

.custom_balloon {
  opacity: 1;
  position: absolute;
  border-radius: 14px;
  padding: 24px 16px;
  bottom: -24px;
  min-width: 273px;
  width: auto;
  max-width: 364px;
  min-height: 116px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgb(255, 255, 255);
  color: var(--secondary-color);
  transition: 0.25s ease;
  display: flex;
  align-items: flex-start;

  .balloon_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 364px;

    .name {
      @include font14Bold;
      margin-bottom: 6px;
    }

    .time {
      @include font14;
      color: var(--grey-color);
      margin: 4px 0;
    }

    .close {
      position: absolute;
      top: -18px;
      right: -6px;
      opacity: 0.5;
      transition: 0.25s ease;
      width: 18px;
      height: 18px;
      &:hover {
        opacity: 1;
      }

      @media screen and (max-width: 767px) {
        top: -2px;
      }
    }

    .button {
    }

    .link {
    }

    .choose {
      @include contentButton;
      @include font14;
      padding: 6px 12px;
      margin: 12px auto 0 0;
      background-color: var(--primary-color);
    }

    @media screen and (max-width: 767px) {
      width: 267px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 12px 12px;
    width: 267px;
    min-height: 140px;
  }
}
